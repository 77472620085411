<template>
  <!-- Fix lost fle -->
  <b-overlay
    :show="loading"
    rounded="sm"
  >
    <b-card
      :title="$t('RATE')"
    >
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row
            v-for="(item, index) of rate"
            :key="index"
          >
            <!-- s_distance -->
            <b-col cols="4">
              <b-form-group
                :label="$t('S_DISTANCE')"
                label-for="s_distance"
              >
                <validation-provider
                  #default="{ errors }"
                  name="s_distance"
                  rules="required"
                >
                  <b-form-input
                    id="s_distance"
                    v-model="rate[index].s_distance"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('S_DISTANCE')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                :label="$t('E_DISTANCE')"
                label-for="e_distance"
              >
                <validation-provider
                  #default="{ errors }"
                  name="e_distance"
                  rules="required"
                >
                  <b-form-input
                    id="e_distance"
                    v-model="rate[index].e_distance"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('E_DISTANCE')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                :label="$t('PRICE')"
                label-for="price"
              >
                <validation-provider
                  #default="{ errors }"
                  name="price"
                  rules="required"
                >
                  <b-form-input
                    id="price"
                    v-model="rate[index].price"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('PRICE')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="1">
              <b-form-group
                :label="$t('ACTION')"
                label-for="action"
              >
                <b-button
                  variant="danger"
                  class="mr-2"
                  @click="deleteRate(index)"
                >
                  {{ $t('DELETE') }}
                </b-button>
              </b-form-group>
            </b-col>

          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="addNewRate()"
            >
              {{ $t('ADD_NEW_RATE') }}
            </b-button>
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('SUBMIT') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import store from '@/store'
import {
  BCard, BFormInput, BForm, BFormGroup, BRow, BCol, BButton,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import CarModelModule from '@/store/root/carModel'

const STORE_MODULE_NAME = 'carModel'

export default {
  components: {
    BCard,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BOverlay,
    // BCardText,
  },
  data() {
    return {
      loading: false,
      dataId: null,
      rate: [{
        s_distance: 0,
        e_distance: 0,
        price: 0,
      }],
      required,
      show: false,
    }
  },
  computed: {
    respDataRate() {
      return store.state[STORE_MODULE_NAME].respDataRate
    },
  },
  created() {
    const userId = this.$route.params.id
    this.dataId = userId
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, CarModelModule)
    this.loading = true
    store
      .dispatch(`${STORE_MODULE_NAME}/getRate`, this.dataId)
      .then(result => {
        this.rate = this.respDataRate.rate
        this.loading = false
        console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      }).catch(error => {
        this.loading = false
        console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    deleteRate(index) {
      this.rate.splice(index, 1)
    },
    addNewRate() {
      this.rate.push({
        s_distance: 0,
        e_distance: 0,
        price: 0,
      })
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          this.loading = true
          const obj = {
            id: this.dataId,
            rate: this.rate,
          }
          store
            .dispatch(`${STORE_MODULE_NAME}/updateRate`, obj)
            .then(result => {
              this.loading = false
              console.log('fetch Success : ', result)
              // this.showToast('success', 'bottom-right')
            }).catch(error => {
              this.loading = false
              console.log('fetchUsers Error : ', error)
              // this.showToast('danger', 'bottom-right')
            })
        }
      })
    },
    showToast(variant, position, title, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style>

</style>
